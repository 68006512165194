import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import React, { useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type { MenuLinkItem } from '../NavbarLink/NavbarLink';
import NavbarLink, { MenuLinkCollapseType } from '../NavbarLink/NavbarLink';
import localStyles from './NavbarCollapseMenu.module.pcss';
import useTranslation from '../../useTranslation';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';

interface Props {
  /**
   * Array of menu link objects.
   */
  menuLinks: MenuLinkItem[];

  /**
   * Main link for collapse menu.
   */
  titleLink: MenuLinkItem;

  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;

  /**
   * Class name(s) to apply to the wrapper element.
   */
  wrapClassName?: string;
}

/**
 * Collapsable menu for use inside of dropdown in the Navbar.
 *
 * @author Willi Hyde, Adam Ayres
 */
const NavbarCollapseMenu: React.FC<React.PropsWithChildren<Props>> = ({
  menuLinks,
  titleLink,
  className,
  wrapClassName
}) => {
  const cx = useClassNameMapper(localStyles);
  const uidSeed = useUIDSeed();
  const [isOpen, setIsOpen] = useState(false);
  const ariaId = uidSeed('navbarCollapse');

  const i18n = useTranslation(EndUserComponent.NAVBAR_COLLAPSE_MENU);
  const { formatMessage, loading: textLoading } = i18n;

  if (textLoading) {
    return null;
  }

  return (
    <div className={cx('lia-wrap', wrapClassName)}>
      <div className={cx('d-flex')}>
        <Dropdown.Item
          as={NavbarLink}
          className={cx('flex-shrink-1', className)}
          collapseType={MenuLinkCollapseType.NONE}
          menuLink={titleLink}
          menuItem
        />
        <span aria-hidden="true" className={cx('lia-divider')} />
        <Dropdown.Item
          as={Button}
          variant={ButtonVariant.UNSTYLED}
          className={cx(className, 'lia-g-navbar-link')}
          onClick={() => setIsOpen(!isOpen)}
          data-testid="NavbarCollapseMenu.Button"
          aria-label={formatMessage(!isOpen ? 'toggleButtonOpen' : 'toggleButtonClose')}
          id={ariaId}
        >
          <span className={cx('lia-icon lia-g-navbar-link-text')}>
            <Icon
              icon={Icons.ChevronRightIcon}
              size={IconSize.FROM_CSS}
              className={cx('lia-icon lia-g-navbar-dropdown-icon', { 'lia-is-rotated': isOpen })}
              testId="NavbarCollapseMenu.Icon"
            />
          </span>
        </Dropdown.Item>
      </div>
      <div
        className={cx('lia-menu', { 'lia-is-open': isOpen })}
        data-testid="NavbarCollapseMenu.Menu"
        aria-labelledby={ariaId}
        role="menu"
      >
        {menuLinks.map(item => (
          <Dropdown.Item
            as={NavbarLink}
            collapseType={MenuLinkCollapseType.ACCORDION}
            menuLink={item}
            key={uidSeed(item.label)}
            className={className}
            menuItem
          />
        ))}
      </div>
    </div>
  );
};

export default NavbarCollapseMenu;
