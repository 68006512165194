import React from 'react';

type NavbarContextInterface = (offsetAmount?: number) => number;

/**
 * Navbar context provides the dropdown offset.
 *
 * @author Willi Hyde, Adam Ayres
 */
const NavbarContext = React.createContext<NavbarContextInterface>(() => 0);

export default NavbarContext;
