import { LinkTarget, LinkType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { LinkProps } from '../../components/community/Navbar/types';
import type {
  ExternalMenuLink,
  InternalMenuLink,
  MenuLinkItem
} from '../../components/community/NavbarLink/NavbarLink';
import { MenuLinkType } from '../../components/community/NavbarLink/NavbarLink';

function linkPropsToExternalMenuLinkItem(link: LinkProps): ExternalMenuLink {
  return {
    type: MenuLinkType.EXTERNAL,
    label: link.linkLabel,
    url: link.url,
    target: link.openInNewTab ? LinkTarget.Blank : LinkTarget.Self
  };
}

function linkPropsToInternalMenuLinkItem(link: LinkProps): InternalMenuLink {
  return {
    type: MenuLinkType.INTERNAL,
    label: link.linkLabel,
    route: link.goesToPage
  };
}

function linkPropsToMenuLinkItem(link: LinkProps): MenuLinkItem {
  switch (link.linkType) {
    case LinkType.External: {
      return linkPropsToExternalMenuLinkItem(link);
    }

    case LinkType.Internal: {
      return linkPropsToInternalMenuLinkItem(link);
    }

    default: {
      return null;
    }
  }
}

export function linkPropsToMenuLinkItems(links: LinkProps[]): MenuLinkItem[] {
  const result: MenuLinkItem[] = links
    ? links
        .map(link => {
          return {
            ...linkPropsToMenuLinkItem(link),
            menuLinks:
              link.children && link.children.length > 0
                ? link.children.map(child => linkPropsToMenuLinkItem(child))
                : null
          };
        })
        .filter(link => !!link)
    : [];

  return result;
}
