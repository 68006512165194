import { LinkTarget } from '@aurora/shared-generated/types/graphql-schema-types';

/**
 * Returns the correct attribute value for the given LinkTarget value
 */
export function calculateTarget(target: LinkTarget) {
  switch (target) {
    case LinkTarget.Blank: {
      return '_blank';
    }

    case LinkTarget.Parent: {
      return '_parent';
    }

    case LinkTarget.Top: {
      return '_top';
    }

    default: {
      return '_self';
    }
  }
}
