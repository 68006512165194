import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { calculateTarget } from '@aurora/shared-client/helpers/links/LinkTargetHelper';
import type IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import useTranslation from '../../useTranslation';
import type { ExternalMenuLink, MenuLinkItem } from '../NavbarLink/NavbarLink';
import { MenuLinkType } from '../NavbarLink/NavbarLink';
import localStyles from './NavbarAnchor.module.pcss';

interface Props {
  /**
   * Class name(s) to apply to the <a> element
   */
  className?: string;

  /**
   * Whether to disable the anchor. Defaults to false.
   * In this context, `disabled` simply means that `pointer-events` do not trigger the link -- keyboard nav should still work.
   */
  disabled?: boolean;

  /** Reference to icon to display beside the anchor, if desired */
  icon?: IconTypes;

  /**
   * Class name(s) to apply to the icon, if present
   */
  iconClassName?: string;

  /**
   * The item to render an anchor for
   */
  item: MenuLinkItem;

  /**
   * Whether to have span wrap around text.
   */
  wrapText?: boolean;

  /**
   * Whether to have a role of menu item.
   */
  menuItem?: boolean;
}

/**
 * Renders a link to a MenuLinkItem
 * @author Rosalyn Rowe, Willi Hyde, Adam Ayres
 */
const NavbarAnchor: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  disabled = false,
  icon,
  iconClassName,
  item,
  wrapText,
  menuItem
}) => {
  const { Link } = useEndUserRoutes();
  const cx = useClassNameMapper(localStyles);
  const uidSeed = useUIDSeed();
  const { label } = item;
  const { target } = item as ExternalMenuLink;
  const { formatMessage } = useTranslation(EndUserComponent.NAVBAR_WIDGET);
  const dataTitle = label.replaceAll(/[^\da-z]+/gi, '_');

  function renderAnchor(href?: string) {
    const disabledClassName = disabled ? 'lia-disabled' : false;

    const contents = (
      <>
        {formatMessage(label)}
        {icon && <Icon size={IconSize.FROM_CSS} icon={icon} className={cx(iconClassName)} />}
      </>
    );

    return (
      <a
        tabIndex={0}
        href={href}
        className={cx(className, disabledClassName)}
        key={uidSeed(label)}
        data-testid={`NavbarLink.${dataTitle}`}
        target={calculateTarget(target)}
        role={menuItem ? 'menuitem' : null}
      >
        {wrapText ? <span className={cx('lia-g-navbar-link-text')}>{contents}</span> : contents}
      </a>
    );
  }

  switch (item.type) {
    case MenuLinkType.INTERNAL: {
      const { route, params, query, options } = item.route;
      const { shallow, locale, scroll } = options ?? {};
      return (
        <Link
          legacyBehavior={true}
          route={route}
          params={params}
          query={query}
          shallow={shallow}
          locale={locale}
          scroll={scroll}
          passHref
        >
          {renderAnchor()}
        </Link>
      );
    }
    case MenuLinkType.EXTERNAL: {
      const { url } = item;
      return renderAnchor(url);
    }
    case MenuLinkType.COLLAPSE: {
      return renderAnchor();
    }

    default: {
      return null;
    }
  }
};

export default NavbarAnchor;
