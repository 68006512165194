import LogoutLink from '@aurora/shared-client/components/authentication/LogoutLink/LogoutLink';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import NavbarContext from '@aurora/shared-client/components/context/NavbarContext/NavbarContext';
import { offsetPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import type { PageAndEmptyParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './NavbarAccountMenu.module.pcss';

/**
 * Display an account menu for use in the Navbar for a
 * partially registered user.
 *
 * @author Willi Hyde
 */
const NavbarAccountMenuPartiallyRegistered: React.FC = () => {
  const cx = useClassNameMapper(localStyles);
  const { showSsoRegistrationModal } = useContext(SsoRegistrationContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NAVBAR_ACCOUNT_MENU
  );

  const { Link } = useEndUserRoutes();
  const getDropdownMenuPosition = useContext(NavbarContext);

  if (textLoading) {
    return null;
  }

  return (
    <Dropdown className={cx('lia-dropdown')}>
      <Dropdown.Toggle
        as={Button}
        variant={ButtonVariant.UNSTYLED}
        data-testid="NavbarAccountMenu"
        className={cx('lia-toggle lia-has-dot')}
        id="lia-navbar-user-menu"
        title={formatMessage('menuToggleTitle')}
      >
        <UserAvatar
          user={null}
          size={IconSize.PX_30}
          className={cx('lia-avatar')}
          ariaHidden={true}
        />
        <span className={cx('lia-dot-indicator')} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right"
        aria-labelledby="lia-navbar-user-menu"
        data-testid="NavbarAccountMenu.Dropdown"
        className={cx('lia-menu')}
        popperConfig={offsetPopperConfig(5, getDropdownMenuPosition())}
        renderOnMount
        role="menu"
      >
        <Dropdown.Item
          className={cx('lia-item')}
          as={Button}
          variant={ButtonVariant.NO_VARIANT}
          onClick={() => showSsoRegistrationModal(true)}
          role="menuitem"
        >
          {formatMessage('completeReg')}
        </Dropdown.Item>
        <div className={cx('lia-item lia-info-box')}>{formatMessage('regReminder')}</div>
        <Dropdown.Divider className={cx('lia-divider')} />
        <Link<PageAndEmptyParams> route={EndUserPages.HelpFAQPage} passHref legacyBehavior={true}>
          <Dropdown.Item className={cx('lia-item')} role="menuitem">
            {formatMessage('helpLink')}
          </Dropdown.Item>
        </Link>
        <LogoutLink className={cx('lia-item dropdown-item')} menuItem />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarAccountMenuPartiallyRegistered;
