import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useCommunityAccess from '../../authentication/AuthenticationLink/useCommunityAccess';
import useTranslation from '../../useTranslation';
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown';
import type { MenuLinkItem } from '../NavbarLink/NavbarLink';
import NavbarLink, { MenuLinkCollapseType, MenuLinkType } from '../NavbarLink/NavbarLink';
import localStyles from './NavbarTextLinks.module.pcss';
import useOverflowTracker from '@aurora/shared-client/components/useOverflowTracker';

interface Props {
  /** Items to render as links */
  items: MenuLinkItem[];
}

/**
 * Renders the text links for the Navbar.
 * Handles the rendering of the 'More' dropdown
 * @author Rosalyn Rowe
 */
const NavbarTextLinks: React.FC<React.PropsWithChildren<Props>> = ({ items }) => {
  const cx = useClassNameMapper(localStyles);
  const overflowElementRef = useRef<HTMLDivElement>();
  const { visibleCount, ref } = useOverflowTracker(items, overflowElementRef);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NAVBAR_TEXT_LINKS
  );
  const { canAccessCommunity } = useCommunityAccess();
  const overflowedItems = items.slice(visibleCount);

  if (textLoading) {
    return null;
  }

  function renderDropdown(): React.ReactElement {
    if (overflowedItems.length > 0) {
      const titleLink: MenuLinkItem = {
        type: MenuLinkType.COLLAPSE,
        label: formatMessage('more'),
        menuLinks: overflowedItems
      };

      return (
        <NavbarDropdown
          menuId="navbarOverflowedLinks"
          titleLink={titleLink}
          menuLinks={titleLink.menuLinks}
        />
      );
    }

    return null;
  }

  const showTextLinks = canAccessCommunity && items?.length > 0;

  if (!showTextLinks) {
    return null;
  }

  return (
    <section className={cx('lia-main-links-container')} ref={ref}>
      <div className={cx('lia-main-links')} ref={overflowElementRef}>
        {items.map((item, i) => {
          return (
            <NavbarLink
              collapseType={MenuLinkCollapseType.DROPDOWN}
              menuLink={item}
              key={item.label + i}
              className={cx({ 'lia-is-hidden': i >= visibleCount })}
            />
          );
        })}
      </div>
      <div className={cx('lia-more')}>{renderDropdown()}</div>
    </section>
  );
};

export default NavbarTextLinks;
