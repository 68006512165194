import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import NavbarContext from '@aurora/shared-client/components/context/NavbarContext/NavbarContext';
import { offsetPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext, useId, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import type { AutoCloseMetadata } from '../../../helpers/dropdown/useAutoclose';
import useAutoclose from '../../../helpers/dropdown/useAutoclose';
import HamburgerIcon from '../../common/HamburgerIcon/HamburgerIcon';
import useTranslation from '../../useTranslation';
import type { MenuLinkItem } from '../NavbarLink/NavbarLink';
import NavbarLink, { MenuLinkCollapseType } from '../NavbarLink/NavbarLink';
import localStyles from './NavbarHamburgerDropdown.module.pcss';

interface Props {
  /** The items to render as navbar links */
  items: {
    sideMenuItems: MenuLinkItem[];
    textLinkItems: MenuLinkItem[];
  };
}

/**
 * A hamburger menu for the navigation bar
 * @author Rosalyn Rowe
 */
const NavbarHamburgerDropdown: React.FC<React.PropsWithChildren<Props>> = ({ items }) => {
  const cx = useClassNameMapper(localStyles);
  const [expanded, setExpanded] = useState(false);
  const { isClosingPermitted, getTrueSource } = useAutoclose('outside');
  const getDropdownMenuPosition = useContext(NavbarContext);
  const id = useId();

  const i18n = useTranslation(EndUserComponent.NAVBAR_HAMBURGER_DROPDOWN);
  const { formatMessage, loading: textLoading } = i18n;

  if (textLoading) {
    return null;
  }

  function handleToggle(
    isOpen: boolean,
    event: React.SyntheticEvent<Dropdown, Event>,
    metadata: AutoCloseMetadata
  ): void {
    const source = getTrueSource(event, metadata);

    if (!isOpen && !isClosingPermitted(source)) {
      return;
    }

    setExpanded(isOpen);
  }

  const hasTextLinkItems = items?.textLinkItems?.length > 0;
  const hasSideMenuItems = items?.sideMenuItems?.length > 0;
  const hasAnyItems = hasTextLinkItems || hasSideMenuItems;

  if (!hasAnyItems) {
    return null;
  }

  return (
    <Dropdown
      className={cx('lia-hamburger', { 'lia-force-hamburger': hasSideMenuItems })}
      onToggle={(isOpen, event, metadata): void => handleToggle(isOpen, event, metadata)}
      show={expanded}
    >
      <Dropdown.Toggle
        className={cx('lia-hamburger-toggle')}
        as={Button}
        variant={ButtonVariant.UNSTYLED}
        aria-label={formatMessage('hamburgerLabel')}
        id={id}
      >
        <HamburgerIcon active={expanded} iconClassName={cx('lia-hamburger-icon')} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={offsetPopperConfig(5, getDropdownMenuPosition())}
        className={cx('lia-hamburger-menu lia-g-navbar-dropdown-menu')}
        role="menu"
      >
        {items.textLinkItems.map((item, i) => {
          return (
            <NavbarLink
              collapseType={MenuLinkCollapseType.ACCORDION}
              menuLink={item}
              key={i}
              className={cx('dropdown-item')}
              wrapClassName={cx('lia-hidden-desktop')}
              menuItem
            />
          );
        })}
        {items.sideMenuItems.map((item, i) => {
          return (
            <NavbarLink
              collapseType={MenuLinkCollapseType.ACCORDION}
              menuLink={item}
              key={i}
              className={cx('dropdown-item')}
              menuItem
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarHamburgerDropdown;
