import { useCallback, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useIsomorphicLayoutEffect } from 'react-use';

type LocalStorageSetter<TypeT> = TypeT | ((previousState: TypeT) => TypeT);

/**
 * Return type for the useLocalStorage hook.
 */
interface LocalStorageReturn<TypeT> {
  /**
   * sets the local storage value
   * @param value value to be set
   */
  setValue: (value: LocalStorageSetter<TypeT>) => void;

  /**
   * the current value of the local storage
   */
  value: TypeT;

  /**
   * removes the current value set in local storage.
   * @param isReactive if true, removes the value from local storage without updating the state that holds the value.
   */
  remove: (isReactive?: boolean) => void;
  /**
   * resets the value for the key to the initial value provided
   */
  reset: () => void;
  /**
   * true when local storage is initializing with specified value. False after initialization is complete.
   */
  loading: boolean;
}
/**
 *
 * Helper method to use local storage with typed object. The component using this hook needs to ensure that it is used only in non SSR views.
 * If this hook is used in multiple components for the same key, then there is a chance that when remove is called, the session is reset back
 * to the initial value depending on the order of how each of the component that uses the hook re-renders. It is best to use this hook only
 * at one component and manage the session from the component.
 * @author Manish Shrestha
 */
export function useLocalStorage<TypeT>(
  key: string,
  initialValue?: TypeT
): LocalStorageReturn<TypeT> {
  const [localStorageValue, setLocalStorageValue] = useState<TypeT>(initialValue);
  const [loading, setLoading] = useState<boolean>(true);
  const removeRef = useRef<boolean>(false);

  const setValue = useCallback(
    (value: TypeT) => {
      localStorage.setItem(key, JSON.stringify(value));
      setLocalStorageValue(value);
    },
    [key]
  );

  /**
   * initializes the localStorageValue state variable based on following condition:
   * 1. if localStorageValue is not defined or null, the first render of the component sets up the initial value
   * 2. if localStorageValue is defined and is not same as storedValue, the localStorageValue is set to the stored value
   */
  useIsomorphicLayoutEffect(() => {
    const storedValue = localStorage.getItem(key);
    if (
      initialValue &&
      (storedValue === null || storedValue === undefined) &&
      !removeRef?.current
    ) {
      setLoading(true);
      setValue(initialValue);
      setLoading(false);
      removeRef.current = false;
    } else if (
      storedValue !== undefined &&
      storedValue !== null &&
      !isEqual(JSON.parse(storedValue), localStorageValue)
    ) {
      setLoading(true);
      setLocalStorageValue(JSON.parse(storedValue));
      setLoading(false);
    }
  }, [initialValue, key, localStorageValue, setValue]);

  /**
   * Returns the current value of local storage
   */
  function getValue(): TypeT {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  }

  /**
   * Removes the value from local storage.
   * @param isReactive - defaults to true. When set to false will only remove the value from local storage and leaves
   * the state value unchanged. If the desire is to not cause rerender when remove then set it to false.
   */
  function remove(isReactive = true): void {
    localStorage.removeItem(key);
    if (isReactive) {
      removeRef.current = true;
      setLocalStorageValue(null);
    }
  }

  /**
   * Resets the local storage value to the initial value
   */
  function reset(): void {
    localStorage.setItem(key, JSON.stringify(initialValue));
    setLocalStorageValue(initialValue);
  }

  /**
   * Sets the local storage value
   * @param value value to set or the callback with previous value set which returns new value
   */
  function setLocalStorage(value: LocalStorageSetter<TypeT>): void {
    if (typeof value !== 'function') {
      setValue(value);
    } else {
      const current = getValue();
      const callback: (previousState: TypeT) => TypeT = value as (previousState: TypeT) => TypeT;
      const updated = callback(current);
      setValue(updated);
    }
  }

  return { setValue: setLocalStorage, value: localStorageValue, remove, reset, loading };
}
