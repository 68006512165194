import type { ApolloError, QueryResult } from '@apollo/client';
import type {
  InternalLinkWithChildrenProp,
  LinkWithChildrenProp
} from '@aurora/shared-generated/types/graphql-schema-types';
import { LinkType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { NavbarLinkProps } from './community/Navbar/types';
import PageEditorQuiltWrapperContext from './context/PageEditorQuiltWrapperContext/PageEditorQuiltWrapperContext';
import { useContext } from 'react';
import EditContext from './context/EditContext/EditContext';
import type { NodesPoliciesQuery, NodesPoliciesQueryVariables } from '../types/graphql-types';
import {
  canReadNode,
  isCommunityPolicy
} from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import useNodesPolicies from '@aurora/shared-client/components/nodes/useNodesPolicies';
import { useInternalLinkNodeIds } from '../helpers/links/useInternalLinkNodeIds';
import { filterLinks } from '../helpers/links/FilterLinksHelper';
import { useContextObjectDataHelper } from './context/useContextObjectFromPath';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';

export interface PropsResult<T> {
  /**
   * The loading state
   */
  loading: boolean;

  /**
   * The props
   */
  props: T;

  /**
   * The Apollo error, if any
   */
  error?: ApolloError;
}

/**
 * Makes a query for policies for the internal links within an array of links.
 * Skips the query if we are in edit mode as we do not care about policies in that case.
 * @param links the array of all links -- will be filtered within
 * @returns the result of the query
 */
function useInternalLinkPolicies(
  links: LinkWithChildrenProp[] = []
): QueryResult<NodesPoliciesQuery, NodesPoliciesQueryVariables> {
  const { showEditControls } = useContext(EditContext);
  const internalLinkNodeIds = useInternalLinkNodeIds(links);

  const variables: NodesPoliciesQueryVariables = {
    constraints: {
      id: { in: internalLinkNodeIds }
    },
    useCanReadNode: true,
    first: internalLinkNodeIds.length
  };

  return useNodesPolicies(
    module,
    variables,
    showEditControls || !internalLinkNodeIds || internalLinkNodeIds.length === 0,
    true
  );
}

/**
 * Given a query result, determines the valid node IDs
 * @param policiesData The query result
 * @returns A Set of valid node IDs
 */
function getValidInternalLinkNodeIds(policiesData?: NodesPoliciesQuery): Set<string> {
  const { edges } = policiesData?.coreNodes ?? {};
  const result = edges
    ? new Set<string>(
        edges
          .filter(edge => {
            const { node } = edge;

            // Community nodes should be visible in navbar, but they do not have a canReadNode property
            if (isCommunityPolicy(node)) {
              return true;
            }

            return canReadNode(node);
          })
          .map(edge => {
            const { node } = edge;

            return node.id;
          })
      )
    : new Set<string>();

  return result;
}

/**
 * A wrapper around NavbarLinkProps that removes the links the user is not able to access.
 * This is bypassed for edit mode.
 */
export default function useFilteredNavbarProps(
  links: NavbarLinkProps
): PropsResult<NavbarLinkProps> {
  const { showEditControls } = useContext(EditContext);
  const { showEditControls: showQuiltWrapperEditControls } = useContext(
    PageEditorQuiltWrapperContext
  );
  const { getObjectDataForRouteAndParams } = useContextObjectDataHelper();

  const { mainLinks = [], sideLinks = [] } = links ?? {};
  const {
    error: policiesError,
    data: policiesData,
    loading: policiesLoading
  } = useInternalLinkPolicies([...mainLinks, ...sideLinks]);

  /** Don't filter for edit mode */
  if (showEditControls || showQuiltWrapperEditControls) {
    return { loading: false, props: links, error: null };
  }

  const validIds = getValidInternalLinkNodeIds(policiesData);

  const linkFilter = (link: LinkWithChildrenProp): boolean => {
    if (link.linkType === LinkType.Internal) {
      const { routeName, params } = link as InternalLinkWithChildrenProp;

      const { entityId } = getObjectDataForRouteAndParams({
        route: routeName as EndUserPages,
        params
      });

      return validIds.has(entityId);
    }

    return true;
  };

  const filteredLinks: NavbarLinkProps = {
    ...links,
    mainLinks: filterLinks(mainLinks, linkFilter),
    sideLinks: filterLinks(sideLinks, linkFilter)
  };

  return {
    loading: policiesLoading,
    props: filteredLinks,
    error: policiesError
  };
}
