import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import NavbarAccountMenuPartiallyRegistered from './NavbarAccountMenuPartiallyRegistered';

export interface Props {
  /**
   * Show or hide the users name.
   */
  showUserName?: boolean;
}

const NavbarAccountMenuMain = dynamic(() => import('./NavbarAccountMenuMain'), {
  ssr: true
});

/**
 * Display an account menu for use in the Navbar for the current user.
 *
 * @author Adam Ayres, Willi Hyde
 */
const NavbarAccountMenu: React.FC<React.PropsWithChildren<Props>> = props => {
  const { isFullyRegistered, isPartiallyRegistered } = useRegistrationStatus();
  const { authUser } = useContext(AppContext);

  if (isPartiallyRegistered) {
    return <NavbarAccountMenuPartiallyRegistered />;
  } else if (isFullyRegistered && authUser) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <NavbarAccountMenuMain {...props} />;
  }

  return null;
};

export default NavbarAccountMenu;
