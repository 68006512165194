import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import canUseNotificationsPolicyQuery from './CanUseNotifications.query.graphql';
import type {
  CanUseNotificationsQuery,
  CanUseNotificationsQueryVariables
} from '../../../types/graphql-types';

/**
 * Helper function used to determine whether the notification icon will render.
 *
 * @author Gaston Manzano
 */
export default function useWillNotificationsIconRender(): boolean {
  const { isFullyRegistered } = useRegistrationStatus();

  const { data, ready } = useQueryWithTracing<
    CanUseNotificationsQuery,
    CanUseNotificationsQueryVariables
  >(module, canUseNotificationsPolicyQuery, { skip: !isFullyRegistered });

  const isNotificationsAvailable =
    ready && checkPolicy(data.community.communityPolicies?.canUseNotifications);

  return isFullyRegistered && isNotificationsAvailable;
}
