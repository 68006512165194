/**
 * Flattens an array of TObjects into an array of TValues. Supports depth-first recursion.
 * @param array the original array
 * @param extract callback that returns a value from an object in the array
 * @param traverse optional callback to recurse the object
 * @returns a flat array of TValue
 */
export function extractDepthFirst<TObject, TValue>(
  array: TObject[],
  extract: (object: TObject) => TValue,
  traverse?: (object: TObject) => TObject | TObject[]
): TValue[] {
  const result: TValue[] = [];

  array?.forEach(value => {
    if (value) {
      const extractedValue = extract(value);

      // also truthy for undefined, note: != not !==
      if (extractedValue != null) {
        result.push(extractedValue);
      }

      const nestedValues = traverse ? traverse(value) : null;

      if (nestedValues) {
        const next = Array.isArray(nestedValues) ? nestedValues : [nestedValues];

        result.push(...extractDepthFirst(next, extract, traverse));
      }
    }
  });

  return result;
}
