import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import useCrossOriginAttribute from '@aurora/shared-client/components/useCrossOriginAttribute';
import Icons from '@aurora/shared-client/icons';
import type { PageAndEmptyParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { getThemeAssetURL } from '@aurora/shared-utils/helpers/theme/ThemeAssetHelper';
import React, { useContext } from 'react';
import { Image, useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './BrandLogo.module.pcss';

interface Props {
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
}

/**
 * Displays brand logo as a link to the home page.
 *
 * @constructor
 *
 * @author Adam Ayres
 */
const BrandLogo: React.FC<React.PropsWithChildren<Props>> = ({ as = 'a', className }) => {
  const Component = as;
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);

  const crossOrigin = useCrossOriginAttribute();
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.BRAND_LOGO);
  const { Link, loading: loadingRoutes } = useEndUserRoutes();
  const { theme } = useContext(ThemeContext);
  const { branchName } = useContext(SwitchBranchContext);
  const imageSource = getThemeAssetURL(
    tenant,
    theme.id,
    theme.basics.customerLogo.imageAssetName,
    theme.basics.customerLogo.imageLastModified,
    null,
    branchName
  );

  function safeFormatMessage(key: string): string {
    return textLoading ? '...' : formatMessage(key);
  }

  if (loadingRoutes) {
    return null;
  }

  return (
    <Link<PageAndEmptyParams> route={EndUserPages.CommunityPage} passHref legacyBehavior={true}>
      <Component className={cx(className)}>
        {imageSource ? (
          <Image
            className={cx('lia-logo')}
            src={imageSource}
            alt={safeFormatMessage('themeLogoAlt')}
            crossOrigin={crossOrigin(imageSource)}
          />
        ) : (
          <Icon
            icon={Icons.KhorosLogoIcon}
            size={IconSize.FROM_CSS}
            title={safeFormatMessage('logoAlt')}
            className={cx('lia-logo', 'lia-khoros-logo')}
          />
        )}
      </Component>
    </Link>
  );
};

export default BrandLogo;
