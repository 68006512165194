import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './HamburgerIcon.module.pcss';

interface Props {
  /**
   * Is the menu active?
   */
  active?: boolean;
  /**
   * Class name(s) to apply to the wrapping element.
   */
  className?: string;

  /**
   * Class name(s) for the burger parts.
   */
  iconClassName?: string;
}

/**
 * Hamburger icon with optional active state.
 *
 * @author Willi Hyde
 */
const HamburgerIcon: React.FC<React.PropsWithChildren<Props>> = ({
  active = false,
  className,
  iconClassName
}) => {
  const cx = useClassNameMapper(localStyles);

  return (
    <span className={cx('lia-hamburger', active ? 'lia-is-active' : '', className)}>
      <span className={cx('lia-burger-part lia-lettuce', iconClassName)} />
      <span className={cx('lia-burger-part lia-tomato', iconClassName)} />
      <span className={cx('lia-burger-part lia-cheese', iconClassName)} />
    </span>
  );
};

export default HamburgerIcon;
