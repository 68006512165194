export type AutoCloseType = boolean | 'inside' | 'outside';

export interface AutoCloseMetadata {
  source: 'click' | 'rootClose' | 'select' | 'keydown';
}

/**
 * Returns functions to enable autoClose behavior for react-bootstrap Dropdowns
 * This feature exists in newer versions of react-bootstrap and can be removed upon upgrade.
 */
export default function useAutoclose(autoClose: AutoCloseType) {
  const isClosingPermitted = (source: string): boolean => {
    // autoClose=false only permits close on button click
    if (autoClose === false) {
      return source === 'click';
    }

    // autoClose=inside doesn't permit close on rootClose
    if (autoClose === 'inside') {
      return source !== 'rootClose';
    }

    // autoClose=outside doesn't permit close on select
    if (autoClose === 'outside') {
      return source !== 'select';
    }

    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTrueSource = (event: any, metadata: AutoCloseMetadata) => {
    const { source } = metadata;
    if (
      event.originalEvent?.currentTarget === document &&
      (source !== 'keydown' ||
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event.originalEvent as any).key === 'Escape')
    ) {
      return 'rootClose';
    } else {
      return source;
    }
  };

  return {
    isClosingPermitted,
    getTrueSource
  };
}
