import communitySsoPropertiesQuery from '@aurora/shared-apollo/queries/CommunitySsoProperties.query.graphql';
import authProvidersQuery from '@aurora/shared-client/components/authentication/AuthProviders.query.graphql';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import AuthFlowContext from '@aurora/shared-client/components/context/AuthFlowContext/AuthFlowContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import { AuthMechanism } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  AuthProvidersQuery,
  AuthProvidersQueryVariables,
  CommunitySsoPropertiesQuery,
  CommunitySsoPropertiesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import Link from 'next/link';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import useCommunityAccess from './useCommunityAccess';
import useEndUserNextPage from '@aurora/shared-client/routes/useEndUserNextPage';

export interface Props {
  /**
   * Class name(s) to apply to the link element.
   */
  className?: string;

  /**
   * Type of authentication flow to use.
   */
  type?: AuthFlow;
}

/**
 * An authentication link that displays when the user is not logged in and
 * will open a [Authentication Modal](/docs/components-authentication-loginform--default) when selected.
 *
 * @author Adam Ayres, Dolan Halbrook, Willi Hyde
 */
const AuthenticationLink: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  type = AuthFlow.LOGIN
}) => {
  const cx = useClassNameMapper();
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;
  const ssoPropertiesQueryResult = useQueryWithTracing<
    CommunitySsoPropertiesQuery,
    CommunitySsoPropertiesQueryVariables
  >(module, communitySsoPropertiesQuery);
  const authProvidersQueryResult = useQueryWithTracing<
    AuthProvidersQuery,
    AuthProvidersQueryVariables
  >(module, authProvidersQuery, {
    skip: !multiAuthEnabled
  });

  const { data: ssoPropertiesData, loading: ssoPropertiesLoading } = ssoPropertiesQueryResult;
  const { data: authProvidersData, loading: authProvidersLoading } = authProvidersQueryResult;
  const { ssoEnabled } = ssoPropertiesData?.community.ssoProperties || {};
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.AUTHENTICATION_LINK
  );
  const { router, loading: routesLoading } = useEndUserRoutes();
  const { nextUrl, nextRoute } = useEndUserNextPage(
    routesLoading ? null : router.getCurrentRouteAndParams()
  );
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const { canAccessLoginActions, canAccessRegistrationActions } = useCommunityAccess();
  const { showAuthenticationModal } = useContext(AuthFlowContext);
  const { getSsoAuthUrl } = useAuthFlow();
  const authProviders = authProvidersData?.authProviders.filter(
    authProvider => authProvider.enabled
  );
  const [localAuthProvider, ...rest] = authProvidersData?.authProviders ?? [];
  const authProviderInfo = {
    localAuthProvider: localAuthProvider ?? null,
    availableAuthProvider: [...rest] ?? [],
    enabledAuthProvider: authProviders ?? []
  };

  if (
    ((type === AuthFlow.LOGIN || type === AuthFlow.MULTI_AUTH_LOGIN) && !canAccessLoginActions) ||
    (type === AuthFlow.REGISTRATION && !canAccessRegistrationActions) ||
    textLoading ||
    ssoPropertiesLoading ||
    authProvidersLoading
  ) {
    return null;
  }
  async function onSignIn(): Promise<void> {
    showAuthenticationModal(type, nextRoute, authProviderInfo);
    setMessageEditingState(null);
  }

  const isMultiAuthLink =
    multiAuthEnabled &&
    authProviders.length === 1 &&
    authProviders[0].authMechanism !== AuthMechanism.Local;

  const isSsoLink = !multiAuthEnabled && checkPolicy(ssoEnabled);

  const renderLink = (
    <Link
      data-testid={`AuthenticationLink.${type}`}
      className={cx(className, 'lia-g-navbar-link')}
      href={getSsoAuthUrl(nextUrl, type === AuthFlow.REGISTRATION)}
    >
      {formatMessage(`title.${type}`)}
    </Link>
  );

  const renderButton = (
    <Button
      variant={ButtonVariant.LINK}
      onClick={onSignIn}
      data-testid={`AuthenticationLink.${type}`}
      className={cx(className, 'lia-g-navbar-link')}
    >
      <span className={cx('lia-g-navbar-link-text')}>{formatMessage(`title.${type}`)}</span>
    </Button>
  );

  return isMultiAuthLink || isSsoLink ? renderLink : renderButton;
};

export default AuthenticationLink;
