import type { LinkWithChildrenProp } from '@aurora/shared-generated/types/graphql-schema-types';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';

/**
 * Removes invalid links from the link structure
 * @param links The link structure
 * @param linkFilter The function to determine if a node is valid
 */
export function filterLinks(
  links: LinkWithChildrenProp[],
  linkFilter: (link: LinkWithChildrenProp) => boolean
): LinkWithChildrenProp[] {
  /** Avoid modifying the original array */
  const allLinks = deepClone(links);

  /** Filter top-level links first -- if a top-level link is inaccessible, we don't care about its children, hide them all! */
  const filteredLinks = allLinks.filter(link => linkFilter(link));

  /** Filter children -- no need to perform recursion here, we only allow one level of nesting at time of writing */
  filteredLinks.forEach(link => (link.children = link.children.filter(child => linkFilter(child))));

  return filteredLinks;
}
