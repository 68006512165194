import type { FormatMessage } from '@aurora/external-types/utils/I18n/i18n';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import localizedCategoriesByLocaleQuery from '@aurora/shared-client/components/community/LocalizedCategoriesByLocale.query.graphql';
import IntlWrapperContext from '@aurora/shared-client/components/context/IntlContext/IntlWrapperContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { useLanguageText } from '@aurora/shared-client/components/languages/UseLanguageText/useLanguageText';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import { offsetPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import type { CategoryPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import type { RouterAndLink } from '@aurora/shared-client/routes/useCustomRouter';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  LocalizedCategoriesByLocaleQuery,
  LocalizedCategoriesByLocaleQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { EndUserPages, EndUserComponent } from '@aurora/shared-types/pages/enums';
import IntlHelper from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import { getLog } from '@aurora/shared-utils/log';
import { canUseDOM } from 'exenv';
import React, { useContext, useEffect } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './LanguagePicker.module.pcss';
import NavbarContext from '@aurora/shared-client/components/context/NavbarContext/NavbarContext';

const log = getLog(module);

interface Props {
  /**
   * Whether to show the icon.
   */
  useIcon?: boolean;
  /**
   * Whether to show the label.
   */
  useLabel?: boolean;
}

interface LanguagePickerToastProps {
  /**
   * The new language that the user has selected.
   */
  newLanguage: string;
  /**
   * The old language that the user was using.
   */
  oldLanguage: string;
  /**
   * optional callback when the toast is removed
   */
  onRemoveToast?: () => void;
}

/**
 * Language picker that allows the user to change the language of the community.
 * @author Martin Sandoval
 */
const LanguagePicker: React.FC<Props> = ({ useIcon, useLabel }) => {
  const cx = useClassNameMapper(localStyles);
  const { locale, setLocale } = useContext(IntlWrapperContext);
  const { formatMessage: languageFormatMessage, loading: languageTextLoading } = useLanguageText();
  const i18n = useTranslation(EndUserComponent.LANGUAGE_PICKER);
  const { formatMessage, loading: textLoading, FormattedMessage } = i18n;
  const { router }: RouterAndLink<EndUserPages, EndUserQueryParams> = useEndUserRoutes();
  const [showLanguagePickerToast, setShowLanguagePickerToast] = useGlobalState(
    GlobalStateType.SHOW_LANGUAGE_PICKER_TOAST
  );
  const { clearToasts } = useToasts();
  const getDropdownMenuPosition = useContext(NavbarContext);

  const {
    data: localizedCategoriesData,
    loading: localizedCategoriesLoading,
    error: localizedCategoriesError
  } = useQueryWithTracing<
    LocalizedCategoriesByLocaleQuery,
    LocalizedCategoriesByLocaleQueryVariables
  >(module, localizedCategoriesByLocaleQuery, {
    fetchPolicy: 'cache-first'
  });

  /**
   * Display the toast info when the user changes the language.
   *
   * @returns {Function} - The function to add the language picker toast
   * @author Martin Sandoval
   * @param formatMessageToast
   */
  function useLanguagePickerToast(
    formatMessageToast: FormatMessage
  ): (props: LanguagePickerToastProps) => void {
    const { addToast } = useToasts();

    /**
     * Adds a language picker toast to the DOM
     */
    function addLanguagePickerToast({
      newLanguage,
      oldLanguage,
      onRemoveToast = () => {}
    }: LanguagePickerToastProps): void {
      const newLocalizedCategory = localizedCategoriesData.localizedCategoriesByLocale.find(
        localizedCategory => localizedCategory.locale === newLanguage
      );

      const oldLocalizedCategory = localizedCategoriesData.localizedCategoriesByLocale.find(
        localizedCategory => localizedCategory.locale === oldLanguage
      );

      const id = `LanguagePicker-${newLanguage}-success`;
      const message = (
        <FormattedMessage
          id="toast.successMessage"
          values={{
            newLanguage: languageFormatMessage(newLanguage),
            oldLanguage: languageFormatMessage(oldLanguage),
            link: chunks => (
              <Button
                variant={ButtonVariant.UNSTYLED}
                className={cx('lia-g-link lia-g-text-sm')}
                onClick={async () => {
                  clearToasts();
                  setShowLanguagePickerToast(previousState => ({
                    ...previousState,
                    showToast: false
                  }));
                  await router.pushRoute<CategoryPageAndParams>(
                    EndUserPages.CategoryPage,
                    {
                      categoryId: newLocalizedCategory?.category?.category?.displayId
                    },
                    {},
                    { shallow: true }
                  );
                }}
              >
                {chunks}
              </Button>
            ),
            backToLanguageLink: chunks => (
              <Button
                variant={ButtonVariant.UNSTYLED}
                className={cx('lia-g-link lia-g-text-sm')}
                onClick={async () => {
                  clearToasts();
                  IntlHelper.setLocalizedCategoryLocaleCookie(oldLanguage);
                  setLocale(oldLanguage);
                  setShowLanguagePickerToast(previousState => ({
                    ...previousState,
                    showToast: false
                  }));
                  await router.pushRoute<CategoryPageAndParams>(
                    EndUserPages.CategoryPage,
                    {
                      categoryId: oldLocalizedCategory?.category?.category?.displayId
                    },
                    {},
                    { shallow: true }
                  );
                }}
              >
                {chunks}
              </Button>
            )
          }}
        />
      );

      const toastProps: ToastProps = {
        id,
        toastVariant: ToastVariant.FLYOUT,
        alertVariant: ToastAlertVariant.INFO,
        title: formatMessageToast(`toast.successTitle`, {
          language: languageFormatMessage(newLanguage)
        }),
        persistRouteChange: false,
        message: () => {
          return message;
        },
        onClose: () => onRemoveToast()
      };
      addToast(toastProps);
    }

    return addLanguagePickerToast;
  }

  const addLanguagePickerToast = useLanguagePickerToast(formatMessage);

  /** Wait for text to finish loading and render the toast if needed */
  useEffect(() => {
    const shouldAddToast = canUseDOM && showLanguagePickerToast?.showToast && !textLoading;
    if (shouldAddToast) {
      addLanguagePickerToast({
        newLanguage: showLanguagePickerToast.language,
        oldLanguage: showLanguagePickerToast.oldLanguage,
        onRemoveToast: () =>
          setShowLanguagePickerToast(previousState => ({
            ...previousState,
            showToast: false
          }))
      });
    }
    /** When the page is navigated away from, if we were showing the toast, disable it until next time we log out. */
    return () => {
      if (shouldAddToast) {
        setShowLanguagePickerToast(previousState => ({ ...previousState, showToast: false }));
      }
    };
  }, [addLanguagePickerToast, setShowLanguagePickerToast, showLanguagePickerToast, textLoading]);

  if (languageTextLoading || textLoading || localizedCategoriesLoading) {
    return null;
  }

  if (localizedCategoriesError) {
    log.error('Error getting localized categories mapping', localizedCategoriesError);
  }

  const allowedLanguages = localizedCategoriesData?.localizedCategoriesByLocale
    .filter(localizedCategory => localizedCategory.category !== null)
    ?.map(localizedCategory => localizedCategory.locale)
    .sort((a, b) => languageFormatMessage(a).localeCompare(languageFormatMessage(b)));

  async function handleOnSelect(newLanguage: string) {
    IntlHelper.setLocalizedCategoryLocaleCookie(newLanguage);
    setLocale(newLanguage);
    setShowLanguagePickerToast({ language: newLanguage, oldLanguage: locale, showToast: true });
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Button}
        variant={ButtonVariant.UNSTYLED}
        data-testid="LanguagePicker.Toggle"
        className={cx('lia-toggle')}
        aria-label={formatMessage('dropdown.title')}
      >
        <Icon
          className={cx('lia-icon', { 'lia-visible-on-sm': !useIcon })}
          testId={'LanguagePicker.Icon'}
          icon={Icons.LanguageIcon}
          size={IconSize.PX_24}
        />
        {useLabel && (
          <small className={cx('lia-language-label')} data-testid={'LanguagePicker.Label'}>
            {languageFormatMessage(locale)}
          </small>
        )}
        <Icon icon={Icons.ChevronDownIcon} size={IconSize.PX_14} className={cx('lia-icon')} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right"
        popperConfig={offsetPopperConfig(0, getDropdownMenuPosition(7))}
        renderOnMount
      >
        {allowedLanguages.map(option => {
          return (
            <Dropdown.Item
              data-testid={`LanguagePicker.Item.${option}`}
              name={option}
              key={option}
              active={option === locale}
              onSelect={() => handleOnSelect(option)}
              aria-label={option === locale ? formatMessage('dropdown.title') : ''}
            >
              {languageFormatMessage(option)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguagePicker;
