import type { QueryResult } from '@apollo/client';
import type {
  NodesPoliciesQuery,
  NodesPoliciesQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import AppContext from '../context/AppContext/AppContext';
import nodesPoliciesQuery from './NodesPolicies.query.graphql';
import useQueryWithTracing from '../useQueryWithTracing';

const log = getLog(module);

/**
 * Gets node-specific policies for several nodes
 * @param module The module
 * @param variables
 * @param skip Whether to skip the graphql call
 * @param ssr Whether to run the query on the server-side render
 * @param useCache Whether to use the 'cache-first' fetch policy. If false, 'cache-and-network' will be used
 *
 * @author Rosalyn Rowe
 */
export default function useNodesPolicies(
  module: NodeModule,
  variables: NodesPoliciesQueryVariables,
  skip = false,
  ssr = false,
  useCache = true
): QueryResult<NodesPoliciesQuery, NodesPoliciesQueryVariables> {
  const { community } = useContext(AppContext);
  const result = useQueryWithTracing<NodesPoliciesQuery, NodesPoliciesQueryVariables>(
    module,
    nodesPoliciesQuery,
    {
      variables,
      fetchPolicy: useCache ? 'cache-first' : 'cache-and-network',
      skip: skip || !community?.id,
      ssr
    }
  );

  if (result?.error) {
    log.error(result.error, 'Error getting node policies for %O', variables);
  }

  return result;
}
