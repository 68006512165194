import Icon from '../Icon/Icon';
import type IconTypes from '../../../public/static/graphics/processed/enums';
import React from 'react';
import { IconSize } from '../Icon/enums';
import { useClassNameMapper } from 'react-bootstrap';
import localStyles from './IconWithCountBadge.module.pcss';

interface Props {
  /**
   * Wrapper component
   */
  wrapper: React.FC<React.PropsWithChildren<{ className: string }>>;
  /**
   * The reference to the Icon
   */
  icon: IconTypes;
  /**
   * Icon title
   */
  iconTitle?: string;
  /**
   * Badge title
   */
  badgeTitle?: string;
  /**
   * Badge class name
   */
  badgeClassName?: string;
}

/**
 * An icon with a badge. The badge only appears when there is a non-nullish
 * `badgeTitle` value.
 *
 * @author Adam Ayres
 */
const IconWithCountBadge: React.FC<Props> = ({
  wrapper: Wrapper,
  icon,
  iconTitle,
  badgeTitle,
  badgeClassName
}) => {
  const cx = useClassNameMapper(localStyles);

  return (
    <Wrapper className={cx('lia-outer', { 'lia-has-unread': badgeTitle })}>
      <span className={cx('lia-inner')}>
        <Icon icon={icon} size={IconSize.PX_24} title={iconTitle} className={cx('lia-icon')} />
      </span>
      {badgeTitle && (
        <span
          data-testid={`IconWithCountBadge.${icon}`}
          className={cx('lia-badge', badgeClassName)}
        >
          {badgeTitle}
        </span>
      )}
    </Wrapper>
  );
};

export default IconWithCountBadge;
