import { extractDepthFirst } from '@aurora/shared-client/helpers/array/ArrayExtractionHelper';
import type {
  InternalLinkWithChildrenProp,
  LinkWithChildrenProp
} from '@aurora/shared-generated/types/graphql-schema-types';
import { LinkType } from '@aurora/shared-generated/types/graphql-schema-types';
import { useContext } from 'react';
import EditContext from '../../components/context/EditContext/EditContext';
import { useContextObjectDataHelper } from '../../components/context/useContextObjectFromPath';
import type { EndUserPages } from '@aurora/shared-types/pages/enums';

/**
 * Determines the IDs of the internal links within an array of links.
 * Returns an empty array if we are in edit mode as the IDs are irrelevant in that case.
 * @param links the array of links
 * @returns an array of internal link IDS
 */
export function useInternalLinkNodeIds(links: LinkWithChildrenProp[] = []): string[] {
  const { showEditControls } = useContext(EditContext);
  const { getObjectDataForRouteAndParams } = useContextObjectDataHelper();

  if (showEditControls) {
    return [];
  }

  const allInternalLinks: InternalLinkWithChildrenProp[] = extractDepthFirst(
    links,
    link => (link.linkType === LinkType.Internal ? link : null) as InternalLinkWithChildrenProp,
    link => link.children
  );

  return allInternalLinks.map(
    ({ routeName: route, params }) =>
      getObjectDataForRouteAndParams({ route: route as EndUserPages, params }).entityId
  );
}
