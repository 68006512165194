import type { RouteWithOptions } from '@aurora/shared-client/routes/useCustomRouter';
import type { LinkTarget } from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import NavbarAnchor from '../NavbarAnchor/NavbarAnchor';
import NavbarCollapseMenu from '../NavbarCollapseMenu/NavbarCollapseMenu';
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown';

export type MenuLinkItem = InternalMenuLink | ExternalMenuLink | CollapseMenuLink;

export const enum MenuLinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  /** A special type used only for 'More'-style dropdowns -- most links with menuItems should still be INTERNAL or EXTERNAL */
  COLLAPSE = 'COLLAPSE'
}

export const enum MenuLinkCollapseType {
  ACCORDION = 'accordion',
  DROPDOWN = 'dropdown',
  /** Used when the item should (finally) be rendered as _just_ a link, not a collapsed menu */
  NONE = 'none'
}

interface MenuLink {
  /**
   * Type of link used. Internal or external.
   */
  type: MenuLinkType;

  /**
   * Text label for the link.
   */
  label: string;

  /**
   * If provided, the top-level item will be rendered as a collapsed menu
   */
  menuLinks?: MenuLinkItem[];
}

interface CollapseMenuLink extends MenuLink {
  /**
   * Type of menu link for collapsed menus
   */
  type: MenuLinkType.COLLAPSE;
}

export interface InternalMenuLink extends MenuLink {
  /**
   * Type of menu link for internal links.
   */
  type: MenuLinkType.INTERNAL;

  /**
   * Route and route options.
   */
  route: RouteWithOptions<EndUserPages, EndUserQueryParams>;
}

export interface ExternalMenuLink extends MenuLink {
  /**
   * Type of menu link for external links.
   */
  type: MenuLinkType.EXTERNAL;

  /**
   * URL string for external links.
   */
  url: string;

  /**
   * How to open the link
   */
  target: LinkTarget;
}

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;

  /**
   * Class name(s) to apply to the wrapper element.
   */
  wrapClassName?: string;

  /**
   * How to render the items that wind up in a collapsed menu
   */
  collapseType: MenuLinkCollapseType;

  /**
   * Whether to have span wrap around text. Default is true.
   */
  wrapText?: boolean;

  /**
   * The item to render
   */
  menuLink: MenuLinkItem;

  /**
   * Whether to have a role of menu item.
   */
  menuItem?: boolean;
}

/**
 * Collapsable menu for use inside of dropdown in the Navbar.
 *
 * @author Willi Hyde, Adam Ayres
 */
const NavbarLink: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  wrapClassName,
  collapseType,
  wrapText = true,
  menuLink: item,
  menuItem
}) => {
  const cx = useClassNameMapper();

  if (item.menuLinks) {
    switch (collapseType) {
      case MenuLinkCollapseType.DROPDOWN: {
        return (
          <NavbarDropdown
            menuId={item.label}
            titleLink={item}
            menuLinks={item.menuLinks}
            className={cx(className)}
          />
        );
      }

      case MenuLinkCollapseType.ACCORDION: {
        return (
          <NavbarCollapseMenu
            titleLink={item}
            menuLinks={item.menuLinks}
            className={cx(className)}
            wrapClassName={cx(wrapClassName)}
          />
        );
      }

      default: {
        // Fall through and continue to the next block -- render as internal/external link
        break;
      }
    }
  }

  /**
   * The anchor does not have a wrapper so the `wrapClassName` is applied to the same
   * element as the normal class.
   */
  return (
    <NavbarAnchor
      className={cx(className, wrapClassName, 'lia-g-navbar-link')}
      item={item}
      wrapText={wrapText}
      menuItem={menuItem}
    />
  );
};

export default NavbarLink;
