import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import {
  merge,
  UndefinedValueMergeBehavior
} from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import toNavbarProps from '../../../helpers/pageeditor/NavbarPropsHelper';
import type {
  LocalNavbarProps,
  PageHeaderNavbarProps
} from '../../../helpers/pageeditor/NavbarPropsHelper';
import EditableWidget from '../../common/Widget/EditableWidget';
import type { WidgetFC } from '../../common/Widget/types';
import useFilteredNavbarProps from '../../useFilteredNavbarProps';
import NavbarNav from './NavbarNav';
import type { NavbarWidgetProps } from './types';
import useLocalizedCategoriesFeatureEnabled from '@aurora/shared-client/components/community/useLocalizedCategoriesFeatureEnabled';

export interface NavbarProps extends LocalNavbarProps, PageHeaderNavbarProps {}

const defaultProps: Partial<NavbarProps> = {
  useTransparentNavbar: false,
  useNavMenuItems: true
};

export function getFinalProps(props: NavbarProps): NavbarProps {
  return merge(defaultProps, props, {
    undefinedMergeBehavior: UndefinedValueMergeBehavior.IGNORE_BEFORE_MERGE,
    mergeNested: false
  });
}

/**
 * Community Navbar Widget
 *
 * @author Luisina Santos
 */
const NavbarWidget: WidgetFC<NavbarWidgetProps> = (widgetProps: NavbarWidgetProps) => {
  const cx = useClassNameMapper();
  const { theme } = useContext(ThemeContext);
  const { navbar: navbarDefaultProps } = theme;
  const { props: links, loading } = useFilteredNavbarProps(widgetProps?.links);
  const { enabled: isLocalizedCategoriesEnabled } = useLocalizedCategoriesFeatureEnabled(module);

  if (loading) {
    return null;
  }

  const validWidgetStyleProps = Object.fromEntries(
    Object.entries(widgetProps.style || {}).filter(([, value]) => value != null)
  );

  const defaultNavbarProps: NavbarWidgetProps = {
    ...widgetProps,
    links,
    style: { ...navbarDefaultProps, ...validWidgetStyleProps }
  } as NavbarWidgetProps;
  const navbarProps = toNavbarProps(defaultNavbarProps);
  const { navbarVariantProps, className: navbarClassName } = navbarProps;
  const finalNavbarVariantProps = {
    ...navbarVariantProps,
    showLanguagePicker: navbarVariantProps.showLanguagePicker && isLocalizedCategoriesEnabled
  };

  const finalProps = getFinalProps({
    navbarVariantProps: finalNavbarVariantProps,
    navbarWidgetProps: defaultNavbarProps,
    className: cx(navbarClassName),
    useNavMenuItems: true,
    useTransparentNavbar: false
  });

  return (
    <EditableWidget<NavbarProps> props={finalProps}>
      <NavbarNav navProps={finalProps} />
    </EditableWidget>
  );
};

export default NavbarWidget;
