import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { useContext } from 'react';

interface CommunityAccess {
  /**
   * Whether the user is registered or the community allows anonymous access.
   */
  canAccessCommunity: boolean;

  /**
   * Whether the user is anonymous and the community allows anonymous access.
   */
  canAccessLoginActions: boolean;

  /**
   * Whether the user is anonymous and anon registration is enabled.
   */
  canAccessRegistrationActions: boolean;
}

/**
 * Helper function used to determine whether the user has access to see the community
 * and the authentication actions.
 *
 * @author Adam Ayres
 */
export default function useCommunityAccess(): CommunityAccess {
  const { canAccess, canRegister } = useContext(AppContext);
  const { isAnonymous, isRegistered } = useRegistrationStatus();

  return {
    canAccessCommunity: isRegistered || canAccess,
    canAccessLoginActions: isAnonymous && canAccess,
    canAccessRegistrationActions: isAnonymous && canRegister
  };
}
