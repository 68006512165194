import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { useLocalStorage } from '@aurora/shared-client/components/useLocalStorage';
import { useEffect } from 'react';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import isEqual from 'react-fast-compare';

type AiSearchChatUnreadCountInfo = {
  /**
   * The timestamp when the unread count was last updated.
   */
  timestamp: number | null;
  /**
   * The unread count.
   */
  count: number;
};

/**
 * Hook to get the AI search chat unread count info. This info is currently
 * shared across all users via localstorage. Once the AI chat is updated to
 * be user specific as part of LIA-93420 this should also be updated to be user
 * specific.
 */
export default function useAiSearchChatUnreadCountInfo(): {
  count: number;
  clear: () => void;
} {
  // We need to use global state to ensure that the count is updated across all
  // usages of this hook. See more about a similar issue here:
  // https://github.com/streamich/react-use/issues/785
  const [globalValue, setGlobalValue] = useGlobalState(GlobalStateType.AI_SEARCH_CHAT_UNREAD_INFO);

  const { value, loading, setValue } = useLocalStorage<AiSearchChatUnreadCountInfo>(
    LocalStorageKeys.AI_SEARCH_CHAT_UNREAD_COUNT,
    {
      timestamp: null,
      count: 0
    }
  );

  useEffect(() => {
    if (!loading && !isEqual(globalValue, value)) {
      setGlobalValue(value);
    }
  }, [globalValue, loading, setGlobalValue, value]);

  useEffect(() => {
    function updateCount() {
      if (window?.AuroraBrandmessengerTracker?.opened === false) {
        setValue({
          count: value.count + 1,
          timestamp: Date.now()
        });
      }
    }

    window.addEventListener('khorosMessageReceived', updateCount);

    return () => {
      window.removeEventListener('khorosMessageReceived', updateCount);
    };
  });

  // If the count is not stale, use the global value.
  // Count is considered stale if timestamp is older than 30 minutes.
  const finalCount =
    !loading && globalValue.timestamp != null && Date.now() - globalValue.timestamp <= 1800000
      ? globalValue.count
      : 0;

  return {
    count: finalCount,
    clear: () => setValue({ count: 0, timestamp: null })
  };
}
